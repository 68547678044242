import Vue from 'vue'
import Router from 'vue-router'

import Home from './components/home/Home.vue'
import About from './components/about/About.vue'
import Resume from './components/resume/Resume.vue'
import Projects from './components/projects/Projects.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/resume',
            name: 'resume',
            component: Resume
        },
        {
            path: '/projects',
            name: 'projects',
            component: Projects
        }
     ]
})

export default router
